<template>
  <div class="card mb-lg-0" id="commentWrite">
    <div class="card-header">
      <h3 class="card-title">Değerlendirme</h3>
    </div>
    <div class="card-body">
      <template v-if="$store.state.isLogin">
        <div class="form-group">
          <Star @response="handleData($event)" />
          <textarea
            class="form-control"
            name="comment"
            rows="6"
            placeholder="Değerlendirmenizi yazın"
          ></textarea>
        </div>
        <button
          @click="sendComment"
          id="sendButton"
          class="btn btn-primary w-100"
        >
          Gönder
        </button>
      </template>
      <template v-else>
        <router-link to="/login" class="btn btn-primary"
          >Görüş yapabilmek için Giriş Yapın</router-link
        >
      </template>
    </div>
  </div>
</template>
<script>
import Star from "../../../components/Star.vue";
export default {
  created() {},
  data() {
    return {
      star: 1,
    };
  },
  methods: {
    handleData: function (e) {
      this.star = e;
    },
    sendComment() {
      document.getElementById("sendButton").disabled = true;
      document.getElementById("sendButton").innerHTML =
        "Değerlendirmeniz gönderiliyor..";
      let comment = document.querySelector("textarea[name=comment]").value;
      if (comment == null) {
        this.$vToastify.warning("Değerlendirme alanı boş bırakılamaz", "Uyarı!");
      } else {
        let commentSave = {
          id: this.$store.state.selectedProfile.user_id,
          comment: comment,
          score: this.star,
          parent_id:0
        };
        this.$store.dispatch("sendComment", commentSave).then((value) => {
          if (value.type == "error") {
            if (value.message == "ERRxNCYS") {
              this.$vToastify.warning("Kendi profiline değerlendirme yapamazsın!", "Uyarı!");
            }
          }
          if (value.type == "success") {
            if (value.message == "SCCxCWS") {
              this.$vToastify.success(
                "Görüş başarılı bir şekilde eklendi",
                "Başarılı!"
              );
              let commentGet = {
                authUserId: this.$store.state.isLogin
                  ? this.$store.state.user_id
                  : 0,
                user_id: this.$store.state.selectedProfile.user_id,
                begin: 0,
                perpage: 20,
              };
              this.$store.dispatch("getComment", commentGet).then((value) => {
                this.$store.state.commentList = value.list;
              });
            }
          }
          if(value.type=="error"){
            if(value.message=='ERRxUNAPPBEF'){
              this.$vToastify.warning(
                "Bu kişiden alınmış randevunuz olmadığı için yorum yapamazsınız",
                "Uyarı!"
              );
            }
          }
          document.querySelector("textarea[name=comment]").value=""
          document.querySelector("textarea[name=comment]").disabled=true
          document.getElementById("sendButton").disabled = true;
          document.getElementById("sendButton").innerHTML = "Gönder";
        });
      }
    },
  },
  components: { Star },
};
</script>