<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Değerlendirmeler</h3>
    </div>
    <div class="card-body p-0">
      <div
        class="media mt-0 p-5"
        v-for="(item, i) in $store.state.commentList"
        :key="'comment' + i"
      >
        <div
          class="d-flex me-3"
          :style="item.approved == 0 ? 'opacity:0.5' : ''"
        >
          <template v-if="item.profile_image_url != null">
            <lazy-image
              class="media-object brround"
              alt="64x64"
              :src="
                'https://storage.terapivitrini.com/' + item.profile_image_url
              "
          /></template>
          <template v-else>
            <lazy-image
              class="media-object brround"
              alt="64x64"
              src="https://storage.terapivitrini.com/storage/files/user_profile.jpg"
          /></template>
        </div>
        <div class="media-body" itemprop="review" itemscope itemtype="https://schema.org/Review">
          <div itemprop="itemReviewed" itemscope itemtype="http://schema.org/MedicalClinic">
            <meta itemprop="name" :content="$store.state.selectedProfile.user_id">
          </div>
          <h5 class="mt-0 mb-1 font-weight-semibold">
            <span itemprop="name">{{ item.fullname }}</span>
            <br />
            <small style="font-size: 10px" v-if="item.approved == 0"
              >Bu görüşü yalnızca siz görüyorsunuz, onaylandığında herkes
              görebilecek</small
            >
            <span class="fs-14">
              <i
                v-for="index in parseInt(item.score)"
                :key="index"
                class="fa fa-star text-yellow"
              ></i>
              <span itemprop="reviewRating" itemscope itemtype="https://schema.org/Rating">
                <span  style="display:none" itemprop="ratingValue">{{ item.score }}</span>
              </span>
            </span>
          </h5>
          <span itemprop="author" itemscope itemtype="https://schema.org/Person">
            <span style="display:none" itemprop="name">{{ item.fullname }}</span>
          </span>
          <meta itemprop="datePublished" :content="item.created_at.split(' ')[0]">
          <span itemprop="publisher" itemscope itemtype="https://schema.org/Organization">
            <meta itemprop="name" :content="item.fullname">
          </span>
          <small class="text-muted"
            ><i class="fa fa-calendar"></i> {{ item.created_at.split(" ")[0] }}
            <i class="ms-3 fa fa-clock-o"></i>
            {{ item.created_at.split(" ")[1] }}
          </small>
          <p class="font-13 mb-2 mt-2">
            {{ item.content }}
          </p>

          <template v-if="item.subComment.length > 0">
            <div
              class="media mt-0 p-5"
              v-for="(a, t) in item.subComment"
              :key="'comment' + t"
            >
              <div
                class="d-flex me-3"
                :style="a.approved == 0 ? 'opacity:0.5' : ''"
              >
                <template v-if="a.profile_image_url != null">
                  <lazy-image
                    class="media-object brround"
                    alt="64x64"
                    :src="
                      'https://storage.terapivitrini.com/' + a.profile_image_url
                    "
                /></template>
                <template v-else>
                  <lazy-image
                    class="media-object brround"
                    alt="64x64"
                    src="https://storage.terapivitrini.com/storage/files/user_profile.jpg"
                /></template>
              </div>
              <div class="media-body">
                
                <h5 class="mt-0 mb-1 font-weight-semibold">
                  {{ a.fullname }}
                </h5>
                <small class="text-muted"
                  ><i class="fa fa-calendar"></i>
                  {{ a.created_at.split(" ")[0] }}
                  <i class="ms-3 fa fa-clock-o"></i>
                  {{ a.created_at.split(" ")[1] }}
                </small>
                <p class="font-13 mb-2 mt-2">
                  {{ a.content }}
                </p>
              </div>
            </div>
          </template>

          <textarea
            v-if="
              item.subComment.length < 1 &&
              $store.state.isLogin &&
              $store.state.user_id == item.user_id
            "
            class="form-control mb-2"
            :placeholder="item.fullname + ' adlı kullanıcıya cevabınızı iletin'"
            :name="'commentAnswer' + i"
          ></textarea>
          <button
            :id="'sendButton' + i"
            v-if="
              item.subComment.length < 1 &&
              $store.state.isLogin &&
              $store.state.user_id == item.user_id
            "
            class="btn btn-primary"
            @click="sendAnswer(i, item.id)"
          >
            Cevapla
          </button>
        </div>
      </div>
      <button
        class="btn btn-primary  w-100"
        @click="loadmore"
        v-if="isshow"
      >
        <span v-if="!isloadcomment">Daha Fazla Yükle</span>
        <div
          v-if="isloadcomment"
          class="spinner-border"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.list=this.$store.state.commentList;
  },
  data() {
    return {
      isloadcomment: false,
      isshow: true,
      list: [],
      currentload: 10,
      perpageload: 2,
    };
  },
  methods: {
    loadmore() {
      if (this.isloadcomment == false) {
        this.isloadcomment = true;
        let cl = this.currentload;
        let commentGet = {
          authUserId: this.$store.state.isLogin ? this.$store.state.user_id : 0,
          user_id: this.$store.state.selectedProfile.user_id,
          begin: cl,
          perpage: this.perpageload,
        };
        this.currentload += this.perpageload;
        this.$store.dispatch("getComment", commentGet).then((value) => {
          if (value.list.length > 0) {
            let arr = value.list;
            arr.forEach((e) => {
              this.$store.state.commentList.push(e);
            });
            this.isloadcomment = false;
          } else {
            this.isshow = false;
          }
        });
      }
    },
    sendAnswer(i, id) {
      document.getElementById("sendButton" + i).disabled = true;
      document.getElementById("sendButton" + i).innerHTML =
        "Görüşünüz gönderiliyor..";
      let comment = document.querySelector(
        "textarea[name=commentAnswer" + i + "]"
      ).value;
      if (comment == null) {
        this.$vToastify.warning(
          "Değerlendirme alanı boş bırakılamaz",
          "Uyarı!"
        );
      } else {
        let commentSave = {
          id: this.$store.state.selectedProfile.user_id,
          comment: comment,
          score: this.star,
          parent_id: id,
        };
        this.$store.dispatch("sendComment", commentSave).then((value) => {
          if (value.type == "error") {
            if (value.message == "ERRxNCYS") {
              this.$vToastify.warning(
                "Kendi profiline değerlendirme yapamazsın!",
                "Uyarı!"
              );
            }
          }
          if (value.type == "success") {
            if (value.message == "SCCxCWS") {
              this.$vToastify.success(
                "Görüş başarılı bir şekilde eklendi",
                "Başarılı!"
              );
              let commentGet = {
                authUserId: this.$store.state.isLogin
                  ? this.$store.state.user_id
                  : 0,
                user_id: this.$store.state.selectedProfile.user_id,
                begin: 0,
                perpage: 20,
              };
              this.$store.dispatch("getComment", commentGet).then((value) => {
                this.$store.state.commentList = value.list;
              });
            }
          }
          document.querySelector("textarea[name=comment" + i + "]").value = "";
          document.querySelector(
            "textarea[name=comment" + i + "]"
          ).disabled = true;
          document.getElementById("sendButton" + i + "").disabled = true;
          document.getElementById("sendButton" + i + "").innerHTML = "Gönder";
        });
      }
    },
    sendComment() {},
  },
};
</script>