<template>
  <div class="container">
    <div class="mb-5 center-block text-center">
      <h4 class="font-weight-semibold">{{ title }}</h4>
    </div>
    <div class="owl-carousel owl-carousel-icons2">
      <div class="item" v-for="(item, i) in list" :key="i">
        <img
          class="image"
          :src="
            'https://img.youtube.com/vi/' +
            (item.path.split('?v=').length == 1
              ? item.path.split('.be/')[1]
              : item.path.split('?v=')[1]) +
            '/hqdefault.jpg'
          "
          @click="showModal(item)"
        />
      </div>
    </div>
    <div
      class="modal fade"
      id="staticBackdrop"
      data-backdrop="static"
      tabindex="-1"
      role="dialog"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    ></div>
    <div
      class="modal fade bd-example-modal-lg"
      data-backdrop="static"
      aria-hidden="true"
      tabindex="-1"
      role="dialog"
      id="videoModal2"
    >
      <div class="modal-dialog modal-lg w-100" role="document">
        <div class="modal-content" style="background: none; border: none">
          <div class="modal-body">
            <button class="btn btn-primary w-100" @click="closeModal">
              Kapat
            </button>
            <iframe
              class="yvideo"
              width="100%"
              height="500px"
              :src="
                index != ''
                  ? 'https://www.youtube.com/embed/' +
                    (index.path.split('?v=').length == 1
                      ? index.path.split('.be/')[1]
                      : index.path.split('?v=')[1])
                  : ''
              "
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.owl-item {
  width: 50% !important;
}
</style>
<script>
export default {
  props: ["title", "list"],
  created() {
    var owl = $(".owl-carousel-icons2");
    owl.owlCarousel({
      loop: false,
      rewind: false,
      margin: 0,
      animateIn: "fadeInDowm",
      animateOut: "fadeOutDown",
      autoplay: false,
      autoplayTimeout: 5000, // set value to change speed
      autoplayHoverPause: true,
      dots: false,
      nav: true,
      autoplay: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 2,
          nav: true,
        },
        1300: {
          items: 2,
          nav: true,
        },
      },
    });
  },
  data() {
    return {
      selectedImage: "",
      index: "",
    };
  },
  methods: {
    showModal(item) {
      this.index = item;
      $("#videoModal2").modal({ backdrop: "static" });
      $("#videoModal2").modal("show");
    },
    closeModal() {
      $(".yvideo").each(function () {
        var el_src = $(this).attr("src");
        $(this).attr("src", el_src);
      });

      $("#videoModal2").modal("hide");
    },
    showImg(index, url) {
      this.selectedImage = "https://storage.terapivitrini.com/" + url;
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },

  mounted() {
    $("#videoModal2").on("show.bs.modal", function (e) {
      window.location.hash = "modal";
    });

    $(window).on("hashchange", function (event) {
      if (window.location.hash != "#videoModal2") {
        $("#videoModal2").modal("hide");
      }
    });
    var owl = $(".owl-carousel-icons2");
    owl.owlCarousel({
      loop: false,
      rewind: false,
      margin: 5,
      animateIn: "fadeInDowm",
      animateOut: "fadeOutDown",
      autoplay: false,
      autoplayTimeout: 5000, // set value to change speed
      autoplayHoverPause: true,
      dots: false,
      nav: true,
      autoplay: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 2,
          nav: true,
        },
        1300: {
          items: 2,
          nav: true,
        },
      },
    });
  },
};
</script>