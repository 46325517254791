<template>
  <div class="container mt-5">
    <div class="mb-5 center-block text-center">
      <h4 class="mb-2 font-weight-semibold">{{ title }}</h4>
    </div>
    <div id="small-categories" class="owl-carousel owl-carousel-icons25">
      <div class="item" v-for="(item, i) in list" :key="i">
        <img
          style="border-radius: 15px"
          @click="() => showImg(i,item.image_url)"
          :src="'https://storage.terapivitrini.com/' + item.image_url"
          alt="img"
        />
      </div>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="selectedImage"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>
<script>
export default {
  props: ["title", "list"],
  created() {

    var owl = $(".owl-carousel-icons25");
    owl.owlCarousel({
      loop: false,
      rewind: false,
      margin: 25,
      animateIn: "fadeInDowm",
      animateOut: "fadeOutDown",
      autoplay: false,
      autoplayTimeout: 5000, // set value to change speed
      autoplayHoverPause: true,
      dots: false,
      nav: true,
      autoplay: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 4,
          nav: true,
        },
        1300: {
          items: 4,
          nav: true,
        },
      },
    });
  },
  data() {
    return {
      index: 0,
      visible: false,
      selectedImage: "",
    };
  },
  methods: {
    showImg(index,url) {
      this.selectedImage='https://storage.terapivitrini.com/'+url;
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  mounted() {
    window.onpopstate = function () {
      document.body.style.overflowY = "auto";
    };
    var owl = $(".owl-carousel-icons25");
    owl.owlCarousel({
      loop: false,
      rewind: false,
      margin: 25,
      animateIn: "fadeInDowm",
      animateOut: "fadeOutDown",
      autoplay: false,
      autoplayTimeout: 5000, // set value to change speed
      autoplayHoverPause: true,
      dots: false,
      nav: true,
      autoplay: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 4,
          nav: true,
        },
        1300: {
          items: 4,
          nav: true,
        },
      },
    });
  },
};
</script>