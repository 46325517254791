var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"mb-5 center-block text-center"},[_c('h4',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"owl-carousel owl-carousel-icons2"},_vm._l((_vm.list),function(item,i){return _c('div',{key:i,staticClass:"item"},[_c('img',{staticClass:"image",attrs:{"src":'https://img.youtube.com/vi/' +
          (item.path.split('?v=').length == 1
            ? item.path.split('.be/')[1]
            : item.path.split('?v=')[1]) +
          '/hqdefault.jpg'},on:{"click":function($event){return _vm.showModal(item)}}})])}),0),_c('div',{staticClass:"modal fade",attrs:{"id":"staticBackdrop","data-backdrop":"static","tabindex":"-1","role":"dialog","aria-labelledby":"staticBackdropLabel","aria-hidden":"true"}}),_c('div',{staticClass:"modal fade bd-example-modal-lg",attrs:{"data-backdrop":"static","aria-hidden":"true","tabindex":"-1","role":"dialog","id":"videoModal2"}},[_c('div',{staticClass:"modal-dialog modal-lg w-100",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content",staticStyle:{"background":"none","border":"none"}},[_c('div',{staticClass:"modal-body"},[_c('button',{staticClass:"btn btn-primary w-100",on:{"click":_vm.closeModal}},[_vm._v(" Kapat ")]),_c('iframe',{staticClass:"yvideo",attrs:{"width":"100%","height":"500px","src":_vm.index != ''
                ? 'https://www.youtube.com/embed/' +
                  (_vm.index.path.split('?v=').length == 1
                    ? _vm.index.path.split('.be/')[1]
                    : _vm.index.path.split('?v=')[1])
                : '',"frameborder":"0","allowfullscreen":""}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }